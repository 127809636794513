nav {
    position: fixed;
    bottom: 0;
    height: 82px;
    background-color: var(--middle);
    width: 100%;
    color: white;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    touch-action: none;
}

@media all and (display-mode: standalone) {
    nav{
        height: 100px;
    }
    
}

nav ul {
    list-style: none;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    max-width: 480px;
}


nav li {
    width: 20%;
    position: relative;
    opacity: .5;
}
nav li.current{
    opacity: 1;
}

li.current:before {
    content: "";
    position: absolute;
    height: 4px;
    width: 100%;
    background: linear-gradient(90deg, #F08236 0%, #ED675D 100%);
}

nav a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
    padding-top: 6px;
}

.nav-text {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 1px;
}


.nav-picto {
    height: 32px;
    object-fit: cover;
    margin-bottom: 10px;
    margin-top: 10px;
}

.header,
header {
    position: fixed;
    z-index: 2024;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: min(12px, 4%);
    pointer-events: none;
    background: linear-gradient(180deg, var(--dark) -50%, var(--transparent) 100%);
}

.header {
    position: sticky;
}

.header-check,
.header-back,
.header-notif,
.header-account {
    background-color: var(--light);
    width: 36px;
    height: 36px;
    display: flex;
    padding: 8px;
    border-radius: 36px;
    cursor: pointer;
    pointer-events: auto;
}


.header-check img,
.header-back img,
.header-notif img,
.header-account img {
    width: 100%;
}

.header-notif{
    opacity:0;
    pointer-events: none;
    transition: all .4s ease-in-out;
    z-index: 20;
}

.header-notif.active{
    opacity:1;
    pointer-events: auto;
}


.header-check {}

.header-middle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

header a {
    height: 22px;
    position: relative;
}

.logo-top {
    cursor: pointer;
    pointer-events: auto;
}
.logo-top-play{
    position: absolute;
    left: 0;
    opacity: 0;
    animation: play 2s 0s linear infinite alternate;
}


@keyframes play {
    0% {
        opacity: 0;
    }

    45% {
        opacity: 0;
    }
    55% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }

}

.preloader {
    position: absolute;
    animation: turn 1s infinite linear;
}

.off {
    opacity: 0;
}

@keyframes turn {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

footer {
    position: fixed;
    z-index: 2024;
    bottom: 0;
    left: 0;
}

.notif {
    transition: all .4s ease-out;
    top: 0;
    left:0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
}

.notif.under {
    opacity: 0;
    pointer-events: none;
}

.notif-wrapper {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90px;
    background-color: var(--dark);
    border-radius: 16px;
    cursor: pointer;
    min-width: 360px;
    width: 80%;
}



.notif-img img {
    height: 42px;
}


.notif-img {
    height: 100%;
    padding: 24px;
}

.notif-content {
    padding: 16px 16px 16px 0;
    flex:1;
}

.notif-title {
    font-weight: 700;
    letter-spacing: .5px;
    text-transform: uppercase;
    font-size: 16px;
    font-style: italic;
}

.notif-text {
    font-size: 14px;
    font-style: italic;
}

.notif-overlay {
    position: fixed;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: .5;
    background-color: #33343d;
    z-index: 0;
}

.notif-round {
    position: absolute;
    right: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background-color: var(--highlight);
    color: white;
    font-size: 10px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, 50%) scale(.8);
    opacity: 0;
    transition: all .4s ease-in-out;
}

.notif-round.show {
    transform: translate(-50%, 50%) scale(1);
    opacity: 1;
}

.header-account {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.header-account-content {
    position: absolute;
    right: 0;
    transform: translateX(calc(100% + 8px));
    text-decoration: none;
    color: white;
    text-transform: uppercase;
    font-size: 9px;
    letter-spacing:.5px;
    font-weight: 500;
}

.header-score {
    text-decoration: none;
    color: white;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: .5px;
    font-weight: 500;
}

.header-level {
    color: var(--highlight);
}

.header-circle {
    width: 40px;
    height: 40px;
    stroke: var(--highlight);
    stroke-width: 5px;
    fill: none;
    position: absolute;
    z-index: 10;
    transform: rotate(-90deg);
}

.header-circle-progress {
    stroke-dasharray: 302;
    stroke-dashoffset: 302;
}

.main-notif {
    position: fixed;
    top: 0;
    z-index: 2000;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    opacity: 0;
    pointer-events: none;
    transition: all .4s ease-in-out;
}

.notif{

}

.main-notif.open {
    opacity: 1;
    pointer-events: auto;
}




.main-notif-middle {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    align-items: center;
    transform: scale(.8);
    transition: all .4s ease-in-out;
    opacity: 0;
}

.open .main-notif-middle {
    transform: scale(1);
    opacity: 1;
}

.swiper {
    width: 100%;
    height: 100%;
}

.main-notif .swiper-wrapper {
    height: 100%;
    align-items: center;
}

.swiper-slide {
    height: 100%;
    width: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 32px;

}


.main-notif-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 16px 0;
    width: 320px;
    background-color: var(--dark);
    border-radius: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.main-notif-description {
    font-size: 14px;
    line-height: 18px;
    padding: 0 8px;
    text-align: center;
    font-style: italic;
}

.notif-slide-gift .main-notif-description {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    font-style: italic;
}


.open .main-notif-content{
    pointer-events: auto;
}

.spot-notif-card {

    width: 100%;
    height: 100%;

    max-width: 290px;
    max-height: 180px;
    aspect-ratio: 290/180;

    background-color: var(--dark);
    margin: 14px 0 16px;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
}

.spot-notif-card.gift{
    width: 100%;
    height: 100%;
    max-width: 380px;
    max-height: 218px;
    aspect-ratio: auto;
    background-color: var(--dark);
    margin: 14px 0 16px;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
}


.spot-notif-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slide-replay .spot-notif-img {
    object-fit: contain;
}

.slide-replay .spot-notif-card {
    margin:0px 0 8px;
}

.spot-notif-gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(0deg, var(--dark) 0%, var(--transparent) 100%);
    opacity: .9;
}

.spot-notif-infos {
    position: absolute;
    bottom: 8px;
    z-index: 3;
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.spot-notif-title {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: left;

}

.spot-notif-type {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 500;
}

.main-notif-title {
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    font-style: italic;
    letter-spacing: 1px;
    max-width: 240px;
}

.main-notif-text {
    text-align: center;
    padding: 0 16px;
    font-size: 14px;
}

.notif-close-spot,
.notif-close {
    position: absolute;
    z-index: 3;
    background-color: var(--highlight);
    border-radius: 30px;
    right: 0px;
    top: 0px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(40%, -40%);
}


.notif-close-spot img,
.notif-close img {
    width: 50%;
    pointer-events: none;
}

.main-notif .home-score {
    margin-top: 12px;
}

.notif-success-badge {
    width: 132px;
    margin: 16px;
}

.notif-success-title {
    font-size: 18px;
    font-style: italic;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.notif-success-text {
    font-size: 14px;
    font-style: italic;
    padding: 0 20px;
    text-align: center;
}

.main-notif-bottom {
    position: fixed;
    z-index: 10;
    bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    pointer-events: none;
}

.swiper-notif-prev,
.swiper-notif-next {
    width: 32px;
    height: 32px;
    background-color: var(--highlight);
    border-radius: 8px;
    margin: 16px;
    pointer-events: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.swiper-notif-next img,
.swiper-notif-prev img{
    width: 80%;
}

.swiper-notif-prev img{
    margin-right:2px;
}

.swiper-notif-next img{
    transform: scaleX(-1);
    margin-left:2px;
}

.swiper-notif-pagination {
    display: flex;
    justify-content: center;
    width: auto !important;
    margin:8px;
}

.swiper-pagination-bullet {
    background-color: var(--extra-light) !important;
    opacity: 1 !important;
}

.swiper-pagination-bullet-active {
    background-color: white !important;
    opacity: 1 !important;
}

.top-lang{
    position: absolute;
    right: 16px;
    z-index: 1;

    display: none;
}

.not-connected .top-lang{
    display: flex;
}
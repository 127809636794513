@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    --orange: #EF734D;
    --orange-light: #E99D66;
    --purple: #86538D;
    --purple-light: #C69BCC;
    --pink: #F0567E;
    --blue: #0396AB;
    --blue-light: #1CD1EA;
    --gold: #F2B772;
    --dark-gold: #d5a66f;
    --red: #EA0352;
    --orange24: #FF3900;
    --highlight: #ED695C;


    --dark: #141627;
    --middle: #1C1F36;
    --light: #252944;
    --extra-light: #454A6B;
    --transparent: #14162700;

    --dark-alpha: #141627c2;

    --size-text: 15px;
    --size-title: 24px;

    /*
    --dark: #121823;
    --middle: #212A3B;
    --light: #2D394E;
    */

    touch-action: pan-x pan-y;
    height: 100%;


}

html,
body {
    width: 100%;
    font-family: "Roboto", sans-serif;
    margin: 0;
    background-color: var(--dark);
    display: flex;
    align-items: center;
    flex-direction: column;
    color: white;
    touch-action: pan-x pan-y;
    overflow-x: hidden;

}

#desktop{
    min-height: 100vh;
}

* {

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

*::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    display: none;
}

*::-webkit-scrollbar-track {
    background: transparent;
    /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, .0);
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */

}


.data-preloader {
    position: fixed;
    top: 0;
    z-index: 2025;
    height: 100%;
    width: 100%;
    background: var(--dark);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cta-preloader {
    position: absolute;
    z-index: 0;
}

.game-cta-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.data-preloader.show {
    background: var(--highlight);
}

.data-preloader.show.off {
    opacity: 0;
    transform: scale(1.05);
    pointer-events: none;
    transition: all 2s ease-in-out;
}

.data-preloader-logo {
    width: 70%;
    opacity: 0;
}

.data-preloader-info {
    position: absolute;
    bottom: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
}

.data-preloader.show .data-preloader-logo,
.data-preloader.show .data-preloader-info {
    opacity: 1;
}

.data-preloader-anim {
    animation: turn 1s infinite linear;
}

.data-preloader-text {
    font-size: 11px;
    text-transform: uppercase;
    margin: 16px;
    letter-spacing: .5px;
    opacity: .7;
}

p {
    margin: 0;
}

.orange {
    background: var(--orange);
    color: white;
}

.screen {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    height: 100%;
    width: 100%;
}


main {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    transition: opacity .4s ease-in-out;
}



.noscroll {
    height: 100vh;
    overflow: hidden;
}

.page {
    justify-content: flex-start;
}

#games main,
#success main {
    padding-top: 60px;
}

* {
    -webkit-user-select: none;
    user-select: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    touch-action: manipulation;

}

body {

    -webkit-touch-callout: none;

    -webkit-user-select: none;

    -khtml-user-select: none;

    -moz-user-select: none;

    -ms-user-select: none;

    user-select: none;

}



input {
    -webkit-user-select: auto;
    user-select: auto;
}


.cta-old {
    text-transform: uppercase;
    background: white;
    padding: 8px 20px 7px;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 14px;
    color: black;
    border-radius: 5px;
    margin-top: 30px;
}

.off {
    opacity: 0;
    pointer-events: none;
}

.logo-home {
    max-width: 320px;
}

.cta {
    cursor: pointer;
    text-transform: uppercase;
    padding: 0 14px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 12px;
    color: white;
    border-radius: 8px;
    margin: 24px 12px;
    text-align: center;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    transition: all .4s ease-in-out;
    text-decoration: none;
    color: white;
}

@media (max-width:540px) {
    .cta {
        min-width: 40vw;
    }

}

.cta.highlight {
    background-color: var(--highlight);
}

.cta.secondary {
    border: 2px solid var(--extra-light);
}

.cta.third {
    background-color: white;
    color: var(--dark)
}

.cta.game,
.cta.orange {
    background-color: var(--orange);
}

.cta.classic,
.cta.purple {
    background-color: var(--purple);
}

.cta.pink {
    background-color: var(--pink);
}

.cta.event,
.cta.blue {
    background-color: var(--blue);
}

.cta.gold {
    background-color: var(--dark-gold);
}

.cta.orange24 {
    background-color: var(--orange24);
}

.cta.red {
    background: linear-gradient(89deg, #ED675D -45%, #EA0352 169.89%);
}

.cta.disabled {
    opacity: .5;
    pointer-events: none;
    cursor: auto;
}


.cta.lock {
    background-color: var(--light);
    opacity: .5;
    pointer-events: none;
}

.cta.preprod {
    pointer-events: auto;
    background-color: var(--orange);
    opacity: 1;
}

.link,
a {
    color: var(--highlight);
    transition: all .4s ease-in-out;
    text-decoration: underline;
    cursor: pointer;
}

label a:visited {
    color: var(--highlight);
}

.link:hover,
a:not(.cta):hover {
    color: var(--purple);
}

.form {
    display: flex;
    flex-direction: column;
}

input {
    background-color: var(--light);
    height: 48px;
    margin: 8px;
    border-radius: 8px;
    border: none;
    font-size: 18px;
    line-height: 18px;
    color: white;
    outline: none;
    padding: 0 10px;
    transition: all .4s ease;
}

textarea:focus,
input:focus {
    background-color: var(--extra-light);
}

.input-auto {
    background-color: var(--extra-light) !important;
}

.psw-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.psw-wrapper input {
    width: 100%;
}

.psw-eye-no,
.psw-eye {
    width: 24px;
    position: absolute;
    right: 24px;
    opacity: .75;
    cursor: pointer;
}

input[type="checkbox"] {
    height: 16px;
    width: 16px;
}

label {
    text-align: left;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.778);
    font-style: italic;
    padding: 8px;
}

.form-optin {
    display: flex;
    width: 100%;
    align-items: center;
}


input::placeholder {
    color: white;
    font-weight: 100;
    opacity: .7;
}

textarea::placeholder {
    color: white;
    font-weight: 100;
    opacity: 1;
}

.none {
    display: none !important;
}

.home-cta {
    display: flex;
    display: none;
    max-width: 480px;
    flex-wrap: wrap;
    justify-content: center;
}

.home-info {
    display: flex;
    display: none;
    flex-direction: column;
    width: 320px;
    margin-top: 24px;
    padding: 24px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}

.home-line {
    text-align: left;
    margin: 6px 0;
    white-space: nowrap;
}

.cta-line {
    display: flex;
    justify-content: center;
    align-items: center;
}

.small-input {
    min-width: 100px;
}

.games-list {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    padding: 0 0 120px;
    margin-top: -4px;
}

.game-item {
    background-color: var(--middle);
    width: 100%;
    max-width: 360px;
    min-height: 240px;
    border-radius: 16px;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 12px;
}

.line {
    display: flex;
    justify-content: center;
    align-items: center;
}

.game-item input {
    min-width: 40px;
}

#map main {
    height: 100%;
}

.spot-card-list {
    height: 480px;
    background: var(--dark);
    z-index: 10;
    bottom: 0;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;

}

.spot-card-main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 12px 6px;
    cursor: pointer;
}

.spot-card {
    background: var(--light);
    width: 150px;
    height: 190px;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    margin-bottom: 8px;
    animation: bkg .8s alternate infinite;
}

.spot-card-main:nth-child(2) .spot-card {
    animation-delay: .2s;
}

.spot-card-main:nth-child(3) .spot-card {
    animation-delay: .4s;
}

@keyframes bkg {
    0% {
        background: var(--light);
    }

    100% {
        background: var(--middle);
    }
}

.spot-card-bkg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.spot-list-map {
    display: flex;
    width: 500px;
}


.spot-card-title {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: left;
    color: white;
    text-decoration: none;
}

.swiper-gift-home .spot-card-title {
    color: var(--dark)
}


.spot-card-type {
    color: var(--purple-light);
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 500;
    text-decoration: none;
    text-align: left;
}

.classic .spot-notif-type {
    color: var(--purple-light);
}

.game .spot-notif-type,
.game .spot-card-type {
    color: var(--orange-light);
}

.event .spot-notif-type,
.event .spot-card-type {
    color: var(--blue-light);
}


.success-card-info,
.spot-card-info {
    background-color: var(--dark);
    color: white;
    position: absolute;
    left: 6px;
    top: 6px;
    font-size: 13px;
    padding: 4px 12px;
    border-radius: 16px;
    z-index: 2;
}


.success-card-circle,
.spot-card-circle {
    position: absolute;
    right: 6px;
    top: 6px;
    height: 23px;
    width: 23px;
    border-radius: 12px;
    border: 5px solid var(--purple);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

#home:not(.not-connected) .home-notconnected {
    display: none;
}

.not-connected .home-map-link {
    order: 0;
    height: 0px;
    pointer-events: none;
    position: fixed;
    z-index: 0;
    top: 0;
    opacity: 0;
}

#cta-gift {
    margin-top: 8px;
}

.not-connected #cta-gift,
.not-connected #cta-profile,
.not-connected .success-card-circle,
.not-connected .spot-card-circle {
    display: none;
}

.skeleton .spot-card-circle {
    border: 5px solid var(--dark);
}

.skeleton .success-card-info {
    background-color: var(--dark);
    color: var(--extra-light);

}

.not-connected .home-map-link {
    order: 1
}

.not-connected .home-notconnected {
    margin-top: -20px;
    order: 2;
}

.not-connected .home-gift {
    order: 3
}

.not-connected .home-discover {
    padding-top: 40px;
    order: 4;
}

.not-connected .home-games {
    order: 6
}

.not-connected .home-success {
    order: 5
}

.not-connected .home-spot24 {
    order: 7
}

.not-connected .home-ranking {
    margin-top: 8px;
    margin-bottom: 20px;
    order: 8;
}

.not-connected .home-footer {
    order: 9
}


.home-video-wrapper {
    height: 43vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    display: none;
}

.not-connected .home-video-wrapper {
    display: flex;
}

.home-video-gradient {

    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(0deg, var(--dark) 0, var(--transparent) 100%);


}

.skeleton .spot-card-title {
    width: 90%;
    height: 14px;
    background-color: var(--light);
    border-radius: 12px;
    margin-bottom: 6px;

    animation: bkg .8s alternate infinite;
}

.skeleton .spot-card-type {
    width: 30%;
    height: 10px;
    background-color: var(--light);
    border-radius: 12px;
    animation: bkg .8s alternate infinite;
}


.spot-card-main:nth-child(2).skeleton .spot-card-type,
.spot-card-main:nth-child(2).skeleton .spot-card-title {
    animation-delay: .2s;
}

.spot-card-main:nth-child(3).skeleton .spot-card-type,
.spot-card-main:nth-child(3).skeleton .spot-card-title {
    animation-delay: .4s;
}

@keyframes bkg-text {
    0% {
        background-color: var(--dark);
    }

    100% {
        background-color: var(--middle);
    }
}


.spot-card-circle.white,
.success-card-circle {
    border: 4px solid white;
}

.event .spot-card-circle {
    border: 4px solid var(--blue);
}

.event .spot-card-circle.checked {
    background-color: var(--blue);
    border: 4px solid var(--blue);
}

.game .spot-card-circle {
    border: 4px solid var(--orange);
}

.game .spot-card-circle.checked {
    background-color: var(--orange);
}

.gift .spot-card-circle {
    border: 4px solid var(--dark);
}

.gift .spot-card-circle.checked {
    background-color: var(--dark);
}



.success-card-circle.checked {
    background-color: var(--pink);
    border: 4px solid var(--pink);
}




.spot-card-circle svg,
.success-card-circle img,
.spot-card-circle img {
    display: none;
}

.spot-card-circle.checked svg,
.success-card-circle.checked img,
.spot-card-circle.checked img {
    display: block;
}


.spot-card-circle.checked {
    background-color: var(--purple);
}

.gift-card-main,
.game-card-main {
    padding: 16px;
}

.game-card-small {
    background: var(--light);
    width: 150px;
    height: 190px;
    border-radius: 16px;
    position: relative;
    overflow: hidden;

}

.gift-card-img,
.game-card-bkg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.success-card-main {
    margin: 12px;
    cursor: pointer;
}

.success-card {
    position: relative;
    width: 305px;
    height: 180px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    justify-content: flex-end;
    align-items: center;
    background-color: var(--light);

}

.success-card-bottom {
    z-index: 3;
    bottom: 0;
    width: 100%;
    display: flex;
    padding: 8px 12px 8px 8px;
    align-items: center;
}

.success-card-gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(0deg, var(--dark) 25%, var(--transparent) 100%);
    opacity: .9;
}

.success-card-bkg {
    height: 100%;
    width: 100%;
    object-fit: cover;
    mix-blend-mode: luminosity;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 0;
}

.checked .success-card-bkg {
    mix-blend-mode: normal;
}

.success-bottom-content {
    padding-left: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    flex: 1;
    margin-bottom: 10px;
}

.success-card-title {
    font-size: 21px;
    line-height: 21px;
    letter-spacing: .5px;
    text-transform: uppercase;
    font-weight: 500;
}


.success-circle {
    width: 18px;
    height: 18px;
    border-radius: 18px;
    border: 3px solid white;
    margin: 3px 6px 3px 0;
}

.success-content-under {
    display: flex;
    font-size: 13px;
    letter-spacing: .5px;
    margin-top: 3px;
}

.success-card-progress {
    width: calc(100% - 16px);
    height: 4px;
    background: rgba(255, 255, 255, .5);
    border-radius: 3px;
    position: relative;
    z-index: 3;
    margin-bottom: 8px;
}

.not-connected .success-card-progress {
    display: none;
}

.success-card-bar {
    width: 0%;
    height: 100%;
    background-color: var(--pink);
}

.success-badge {
    width: 72px;
}

.success-badge img {
    width: 100%;
}


.home-data-box {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--middle);
    border-radius: 16px;
    text-decoration: none;
    color: white;
}

.home-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

}

.home-data-list {
    display: flex;
    width: 90%;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 16px;
}

.home-data img {
    height: 36px;
}

.home-data-title {
    text-transform: uppercase;
    font-style: italic;
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 8px;
    color: white !important;
}

.home-data-text {
    font-size: 15px;
    font-weight: 500;
    margin-top: 8px;
}

.box-total {
    color: white !important;
}

.box-number {
    color: var(--orange);
}

.home-data-box img {
    width: 36px;
}

.home-score {
    margin-top: -96px;
    border-radius: 100px;
    overflow: hidden;
    width: 190px;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: var(--dark);
}

.bkg-circle {
    width: 160px;
    height: 160px;
    position: absolute;
    border-radius: 180px;
    background-image: conic-gradient(from 180deg at 50% 50%, #F07E3A 20deg, #EC1453 180.67402839660645deg, #8D4F8A 340deg);
}

.home-score-round {
    width: 200px;
    height: 200px;
    position: absolute;
    z-index: 2;
}

.circle-score {
    stroke-dasharray: 346 346;
    stroke-dashoffset: -31;
    stroke-opacity: .8;

    stroke: var(--dark);
    fill: var(--dark);

    stroke-width: 30px;

    transform: rotate(90deg);
    transform-origin: 50% 50%;

}

.circle-hide {
    stroke-dasharray: 346;
    stroke-dashoffset: 284;
    stroke: var(--dark);
    fill: none;
    stroke-width: 30px;
    transform: rotate(58deg);
    transform-origin: 50% 50%;
}

.top-circle {
    position: absolute;
    width: 22px;
    height: 168px;
    z-index: 3;
    display: flex;
    align-items: flex-end;
    transform: rotate(35deg);
}

.round-top-circle {
    width: 21px;
    height: 21px;
    background: var(--dark);
    border: 3px solid white;
    border-radius: 22px;
    position: absolute;
}

.home-score-main {
    position: absolute;
    color: white;
    z-index: 10;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.big-score {
    font-size: 32px;
    line-height: 32px;
    font-style: normal;
    font-weight: 600;
    margin-top: 12px;
}

.pts-score {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 12px;
}

.lvl-score {
    position: absolute;
    color: white;
    font-size: 14px;
    text-transform: uppercase;
    z-index: 10;
    bottom: 22px;
}

.map-top {
    width: 100%;
    z-index: 1;
    position: relative;
    top: 0;
    background-color: var(--middle);
}


#map-container {
    width: 100%;
    height: calc(50vh - 8px);
    min-height: 320px;
    z-index: 1;
    position: relative;
    top: 0;
    background-color: var(--middle);
    opacity: 0;
    transition: all 1s ease-in-out;
}

#map-container.visible {
    opacity: 1;
}

.fiche-spot-opened main,
.fiche-opened main,
.fiche-spot-opened .spot-card-list,
.fiche-spot-opened #map-container {
    opacity: .25;
}


.spot-card-list,
#map-container {
    transition: opacity .4s ease-in-out;
}

.fiche-event,
.fiche-gift,
.fiche-contact,
.fiche-faq,
.fiche-sports,
.fiche-countries,
.fiche-gift,
.fiche-event,
.fiche-success,
.fiche-spot {
    position: fixed;
    z-index: 2024;
    width: 100vw;
    max-width: 600px;
    height: 100%;
    top: 0;
    right: 0;
    background: var(--dark);
    color: black;
    transform: translateX(100%);
    transition: transform .4s ease-in-out;
}

.load .fiche-event,
.load .fiche-gift,
.load .fiche-contact,
.load .fiche-faq,
.load .fiche-sports,
.load .fiche-countries,
.load .fiche-gift,
.load .fiche-event,
.load .fiche-success,
.load .fiche-spot {
    transition: all 0s linear;
}


.fiche-spot {
    z-index: 2025;
}

.fiche-event.open,
.fiche-gift.open,
.fiche-contact.open,
.fiche-faq.open,
.fiche-sports.open,
.fiche-countries.open,
.fiche-success.open,
.fiche-spot.open {
    transform: translateX(0%);
    z-index: 2025;
}

.fiche-faq,
.fiche-contact {
    overflow-y: scroll;
}

.fiche-content {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    top: 0;
    padding-bottom: 140px;
}

.fiche-main-picture {
    width: 100%;
    aspect-ratio: 1/1;
    max-height: 400px;
    object-fit: cover;
}

.fiche-gift .fiche-main-picture {

    aspect-ratio: auto !important;
    max-height: none !important;

}

.header {
    background: linear-gradient(180deg, var(--dark) -50%, var(--transparent) 100%);
    position: sticky;
    top: 0;
}

.fiche-header {
    background: linear-gradient(180deg, var(--dark) -50%, var(--transparent) 100%);
    position: sticky;
    z-index: 2000;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    pointer-events: none;
}

.fiche-top {
    position: relative;
    overflow: hidden;
    margin-bottom: max(-20vw, -80px);
    background: var(--light);
}

.fiche-top-gradient {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: min(60vw, 300px);
    z-index: 2;
    background: linear-gradient(0deg, var(--dark) 0%, var(--transparent) 100%);
}

.circle {
    margin: 0 12px;
}


.check {
    width: 20px;
    height: 20px;
    border-radius: 24px;
    border: 3px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .4s ease-in-out;
}

.check svg {
    display: none;
}

.check.checked {
    background-color: white;
}

.check.checked svg {
    display: block;
}

.cta-center {
    background-color: var(--light);
    width: 36px;
    height: 36px;
    display: flex;
    padding: 8px;
    border-radius: 36px;
    cursor: pointer;
    pointer-events: auto;
    position: absolute;
    bottom: 12px;
    right: 12px;
    z-index: 12;

}

.cta-center.active {
    background-color: var(--purple);
}

.cta-center img {
    width: 100%;
}

.fiche-success .fiche-main-picture {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    mix-blend-mode: luminosity;

}

.fiche-success .fiche-info {
    text-align: center;
    align-items: center;
}

.fiche-success .fiche-top {
    background: var(--light);
}

.fiche-success .fiche-top-gradient {
    height: min(50vw, 300px);
}

.fiche-success-objectif,
.fiche-success-points {
    font-size: 18px;
    font-style: italic;
    font-weight: 700;
    letter-spacing: .5px;
    text-transform: uppercase;
}

.fiche-success-objectif {
    color: var(--pink)
}

.fiche-success-info-bottom {
    display: flex;
    margin-top: 20px;
    align-items: center;
}

.not-connected .success-progress,
.not-connected .fiche-success-progress,
.not-connected .fiche-success .small-circle,
.not-connected .fiche-success-objectif {
    display: none;
}

.small-circle.pink {
    background: var(--pink);
}

.fiche-success-badge {
    width: 120px;
    height: 120px;
}

.fiche-success-title {
    font-size: 24px;
    font-style: italic;
    font-weight: 800;
    line-height: 32px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 12px 0 8px;
}

.fiche-success-description {
    font-size: 15px;
    line-height: 17px;
    padding: 0 24px;
}

.fiche-success-progress {
    height: 5px;
    width: 80%;
    max-width: 200px;
    background: white;
    border-radius: 7px;
    margin-top: 18px;
}

.fiche-success-bar {
    width: 0%;
    height: 100%;
    background: var(--pink);
}

.fiche-success-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.fiche-success-list {
    display: flex;
    flex-direction: column;
    padding: 12px;
    margin-top: 12px;
}

.fiche-success-poi {
    width: 100%;
    height: 90px;
    background: var(--middle);
    border-radius: 16px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    color: white;
    cursor: pointer;
    position: relative;
    transition: background-color .4s ease-in-out;
}

.fiche-success-poi:hover {
    background: var(--light);
}

.fiche-poi-left {
    background-color: var(--dark);
    width: 112px;
    height: 82px;
    border-radius: 8px;
    overflow: hidden;

}

.fiche-poi-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.fiche-poi-right {
    flex: 1;
    padding: 0px 32px 0px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    font-size: 15px;

}

.fiche-poi-check {
    position: absolute;
    right: 6px;
    top: 6px;
    height: 18px;
    width: 18px;
    border-radius: 12px;
    border: 3px solid white;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
}

.fiche-poi-check img {
    width: 10px;
    opacity: 0;
}

.fiche-poi-check.checked {
    background-color: var(--pink);
    border-color: var(--pink);
}

.fiche-poi-check.checked img {
    opacity: 1;
}


.fiche-poi-title {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    font-style: italic;
}

.fiche-poi-under {
    color: var(--pink)
}

.fiche-bottom {
    position: fixed;
    z-index: 10;
    bottom: 0;
    width: 100%;
    background: var(--dark);
}


.fiche-bottom .cta {

    margin: 8px 12px 32px;

}

.fiche-bottom-gradient {
    position: absolute;
    top: 0;
    height: 10vh;
    width: 100%;
    background: linear-gradient(0deg, var(--dark) 0%, var(--transparent) 100%);
    transform: translateY(-100%)
}



.cta-map-filter {
    background-color: var(--light);
    width: 36px;
    height: 36px;
    display: flex;
    padding: 8px;
    border-radius: 36px;
    cursor: pointer;
    pointer-events: auto;
    position: absolute;
    bottom: 12px;
    left: 12px;
    z-index: 12;
}

.cta-map-filter img {
    width: 100%;
}

.filter-wrapper {
    position: absolute;
    left: 60px;
    bottom: 12px;
    z-index: 12;
    height: 36px;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    display: flex;
}

.filter {
    height: 28px;
    padding: 0 6px 0 12px;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    display: flex;
    border-radius: 32px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}


.filter.pink {
    background-color: var(--pink);
}

.filter.purple {
    background-color: var(--purple);
}

.filter.blue {
    background-color: var(--blue);
}

.filter.active {
    background: white;
    color: var(--orange);
}


.filter.active.pink {
    background: white;
    color: var(--pink);
}

.filter.active.purple {
    background: white;
    color: var(--purple);
}

.filter.active.blue {
    background: white;
    color: var(--blue);
}



.close-filter {
    height: 16px;
    width: 16px;
    border: 1px solid white;
    border-radius: 16px;
    margin-left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-filter img {
    width: 80%;
}

.list-slide-events,
.list-slide-success {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    margin-bottom: 30px;
}

.event-list-page,
.success-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    overflow: hidden;
    padding-bottom: 120px;
}

.list-slide-title {
    font-weight: 700;
    font-style: italic;
    font-size: 20px;
    letter-spacing: 1px;
    padding-left: 16px;
    text-transform: uppercase;
}

#map-container-nav {
    width: 100%;
    height: calc(100% - 140px);
    top: 60px;
    z-index: 1;
    position: fixed;
    background-color: var(--middle);
}

@media all and (display-mode: standalone) {
    #map-container-nav {
        height: calc(100% - 10px);
    }
}

#map #cta-map {
    display: none;
}

#map main{
    min-height: 760px;
}

.fiche-success-around {
    background-color: var(--light);
    color: white;
    position: relative;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
    border-radius: 80px;
    font-size: 26px;
    font-style: italic;
    font-weight: 700;
    margin-left: 3px;
}

.fiche-success-around sup {
    font-size: 10px;
    font-weight: 400;
}

.fiche-success-around.checked {
    background-color: var(--pink);
}


.fiche-success-list.around {
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.around .fiche-poi-check {
    border: none;
    display: none;
}


.home-map {
    height: 200px;
    width: 100%;

    opacity: 1;
    background: var(--middle);
    pointer-events: none;
    overflow: hidden;
}


.home-map-link {
    height: 200px;
    width: 100%;
    opacity: 1;
}

.home-map .wemap-livemap,
.map-top .wemap-livemap {
    height: 120% !important;
}

.success-item,
.address-map {
    cursor: pointer;
}

.home-map .wemap-livemap {
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.home-map.ready .wemap-livemap {
    opacity: .5;
}

.home-big-title {
    font-size: 24px;
    margin-top: 24px;
    font-weight: 900;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-style: italic;
}

.home-title {
    font-size: 24px;
    line-height: 26px;
    font-weight: 900;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 16px;
}

.account-gift-swiper,
.swiper-gift-home,
.swiper-games-home,
.swiper-success-home,
.swiper-spot-home {
    display: flex;
    width: 100%;
    overflow-x: scroll;
}

.swiper-success-home {
    margin-top: 16px;
}

#home main {
    padding-bottom: 120px;
}

.home-games,
.home-success,
.home-discover {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
}

.gift-card-main,
.game-card-main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 12px 6px;
    cursor: pointer;
    width: 220px;
    margin-right: 16px;
}

.gift-card,
.game-card {
    width: 220px;
    height: 300px;
    background: var(--light);
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    margin-bottom: 8px;
}

.gift-card-img,
.game-card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.game-card-type {
    color: var(--orange-light);
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 500;
}

.gift-card-type {
    color: var(--highlight);
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 500;
}


.home-spot24 {
    width: 100%;
    background: white;
    margin: 40px;
    color: var(--dark);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.footer-line {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 12px;
}

.footer-line a {
    margin: 0 12px;
}

.social-title {
    text-transform: uppercase;
    letter-spacing: .5px;
    font-size: 15px;
    font-weight: 400;
    margin-top: 12px;
    margin-bottom: 4px;
}

ul.social-list {
    list-style: none;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
}

.social-item {
    padding: 14px;
}

.footer-sep {
    width: 70%;
    height: 1px;
    background-color: rgba(255, 255, 255, .3);
}

.footer-social {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-sep {
    margin: 12px 0;
}

.footer-legals a {
    font-size: 12px;
    font-weight: 300;
    color: white;
    text-decoration: none;
    opacity: .5;
    margin: 6px 12px;
}

.footer-legals {
    width: 280px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.home-gift {
    background: white;
    color: var(--dark) !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 40px;
}

.scratch-top {
    width: 104%;
    transform: translateY(-40%);
}

.scratch-bottom {
    width: 104%;
    transform: translateY(50%) rotate(180deg) scaleY(-1);
}


.home-spot24 .scratch-top {
    width: 104%;
    transform: translateY(-40%) scaleY(-1);
}

.home-spot24 .scratch-bottom {
    width: 104%;
    transform: translateY(50%) rotate(180deg)
}

.logo-expo {
    width: 90%;
    margin-top: 16px;
}

.countdown {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 12px;
    margin-bottom: 24px;
}

.countdown-number {
    background-color: #F3F5F7;
    font-size: 48px;
    font-weight: 700;

    width: 70px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.countdown-block {
    margin: 6px;
}

.countdown-text {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    margin-top: 6px;
}

.home-events {
    margin-top: 40px;
}

.rank-flag {
    width: 100%;
}

/*
.first .rank-position {
    color: #f6d17c;
}
.second .rank-position {
    color: #eaeaea;
}
.third .rank-position {
    color: #F5BB78;
}
*/

.event-card {
    width: 335px;
    height: 220px;
    background-color: var(--light);
    margin: 32px 0 16px;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
}

.event-date {
    position: absolute;
    z-index: 3;
    background-color: var(--dark);
    left: 8px;
    top: 8px;
    padding: 8px;
    border-radius: 8px;
}

.event-card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    position: relative;
}

.event-card-gradient {
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(0deg, var(--dark) 25%, var(--transparent) 100%);
    opacity: .9;
}

.event-infos {
    position: absolute;
    bottom: 8px;
    z-index: 3;
    left: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.event-card-title {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: left;

}

.event-card-type {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--blue)
}

.small-event {
    background-color: var(--light);
    border-radius: 16px;
    display: flex;
}

.spot24-pic {
    width: 240px;
    height: 240px;
    object-fit: cover;
    border-radius: 16px;
    margin: 6px;
}

.spot24-pictures {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin: 12px 0;
}

.second-line {
    align-self: flex-end;
}

.pictures-line {
    display: flex;
    width: auto;
}

.bkg-video {
    flex: 1;
    position: relative;
    top: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: black;
}

#complete_account main {
    display: block;
    min-height: 100%;
}

#complete_account .bkg-video {
    height: 30vh;
}

.bkg-video video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 1;
    position: absolute;
}

#reset main,
#login main {
    /* position: fixed; */
    bottom: 0;
    z-index: 2;
    min-height: 760px;
    height: 100%;
}

#new_account main{
    min-height: 780px;
}

#reset header {
    justify-content: center;
}

.form-complete-step1,
.form-complete-step2,
.form-create-step2,
.form-create-step1,
.login-step1,
.login-step2,
.login-step3 {
    background: var(--dark);
    width: 100%;
    padding: 0 32px 32px;
    display: flex;
    flex-direction: column;
}

#update_account .form-create-step1 {
    padding: 0 16px 0px;
}

.login-title {
    width: 100%;
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background: linear-gradient(0deg, var(--dark) 0%, var(--transparent) 100%);
    position: absolute;
    bottom: 0;
    z-index: 2;
    margin-bottom: -1vh;
}

.login-logo {
    width: 70%;
    margin-bottom: 4vh;
}



#account main,
#update_account main {
    padding-top: 72px;
}

.main-notif .rank-round,
#account .rank-round,
#update_account .rank-round {
    position: relative;
}

#account .rank-round,
#update_account .rank-round {
    position: relative;
}

#update_account .header-account-content {
    display: none;
}


h1 {
    font-size: 24px;
    font-weight: 900;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-style: italic;
}

#games h1,
#success h1 {
    margin: 0;
}

#new_account h1 {
    font-size: 18px;
    line-height: 18px;
}


.page-title-wrapper {
    padding: 30px 16px 40px;
}

#account .score-main.home-score {
    margin-top: 0;
    transform: scale(.6) translateX(-36%);
    position: absolute;
    left: 12px;
    background: var(--middle);
}

#account .score-main .circle-score {
    stroke: var(--middle);
    fill: var(--middle);

}

#account .score-main .circle-hide {
    stroke: var(--middle);
}

#account .score-main .lvl-score {
    display: none;
}

.account-first-wrapper {
    margin-top: 16px;
    margin-bottom: 16px;
}

.account-welcome {
    font-style: italic;
}

.account-full-name {
    font-size: 21px;
    font-weight: 700;
    text-transform: uppercase;
    font-style: italic;
}

.account-pseudo {
    color: var(--orange);
    font-size: 16px;
    font-weight: 700;
    font-style: italic;

}

.account-score-wrapper {
    width: 90%;
    background-color: var(--middle);
    border-radius: 16px;
    position: relative;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px;
}

.account-score-right {
    position: relative;
    height: 100%;
    width: 132px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.account-score-content {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    position: relative;
    z-index: 2;
}

.account-level {
    font-size: 24px;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
    color: white;
}

.account-next {
    font-size: 14px;
    font-style: italic;
    color: white;
}

.account-rank {
    font-size: 14px;
    margin-top: 4px;
    font-weight: 700;
    color: var(--orange);
    font-style: italic;
    text-transform: uppercase;
}

#account #cta-rank {
    margin-bottom: 8px;
}

#account main {
    padding-bottom: 100px;
}

.account-success-list {
    margin-top: 16px;
    width: 98%;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
}

.account-success-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 25%;
    padding: 10px;
    opacity: .25;
}


.account-success-item.unlock {
    opacity: 1;
}

#desktop {
    background-color: var(--dark);
    background-image: url('../img/bkg/bkg3.jpg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main-iframe {
    width: 100%;
    max-width: 420px;
    height: 100%;
    max-height: 820px;
    border-radius: 16px;
    box-shadow: 0px 0px 50px rgba(20, 22, 39, 0.25);
    position: relative;
    z-index: 2;
    background-color: var(--highlight);
}

@media (max-height:850px) {

    .main-iframe {
        transform: scale(.8);
    }
}

@media (min-height:1200px) {

    .main-iframe {
        transform: scale(1.2);
    }
}


.qr-code {
    position: absolute;
    bottom: 32px;
    right: 32px;
    z-index: 1;
}

.account-footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
}

.account-footer-item {
    display: flex;
    justify-content: space-between;
    padding: 24px 16px;
    border-top: 1px solid var(--light);
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    transition: all .4s ease-in-out;
    position: relative;
}

.account-footer-item:hover {
    background-color: var(--light);
}

.footer-chevron {
    max-width: 28px;
}

.footer-chevron.lang-flag {
    width: 28px;
    height: 28px;
    background: white;
    border-radius: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    position: absolute;
    right:0;
    transform:translate(-60%,-20%);
    border: 2px solid white;
}

#cta-debug span {
    color: var(--highlight)
}


.country-select-title {
    color: white;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;
    font-style: italic;
}

.countries-list {
    display: flex;
    flex-direction: column;
    color: white;
}

.fiche-countries {
    overflow-y: scroll;
}

.bithdate-box,
.sport-box,
.country-box {
    display: flex;
    position: relative;
    align-items: center;
}

#input-birthdate {
    position: absolute;
    z-index: 1;
    width: 100%;
    margin: 8px;
}

.bithdate-box input {
    margin: 0 !important;
}

.bithdate-box {
    margin: 8px;
    overflow: hidden;
}

#input-birthdate-text {
    width: 100%;
    z-index: 2;
    pointer-events: none;
}

.country-flag {
    width: 60px;
    height: 45px;
    border-radius: 8px;
    background: var(--dark)
}


.country-flag img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.country-mini-flag {
    width: 40px;
    height: 30px;
    opacity: 0;
    position: absolute;
    border-radius: 4px;
    right: 20px;
    pointer-events: none;
}

.country-mini-flag.active {
    opacity: 1;
}

.sport-item,
.country-item {
    position: relative;
    padding: 16px;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    background: var(--light);
    margin: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all .4s ease-in-out;
    color: white;
}

.sport-item {
    height: 64px;
}

.sport-item.active,
.country-item.active {
    background-color: var(--extra-light);
}

#cta-debug {
    background-color: var(--light);
}

#sport-box {}

#input-birthdate {
    min-width: 100%;
    display: flex;
    align-items: center;
}

input::-webkit-date-and-time-value {
    margin-top: 0px;
    text-align: left !important;
    inline-size: 100%;
}

#input-birthdate div {
    text-align: left;
}

input[type="date"] {
    display: block;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.2em;
}

#input-sport-name,
#input-country-name {
    width: 100%;
}

.sport-box img {
    position: absolute;
    right: 24px;
    opacity: .5;
}

.sport-box,
.country-box {
    cursor: pointer;
}

.fiche-faq-list {
    color: white;
}

#ranking main {
    padding-top: 60px;
    padding-bottom: 120px;
}

.account-success-wrapper,
.account-gift-wrapper {
    width: 100%;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.account-title {

    font-weight: 700;
    font-style: italic;
    font-size: 20px;
    letter-spacing: 1px;
    padding-left: 16px;
    text-transform: uppercase;

}

.fiche-gift-type {
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 700;
    font-style: italic;
    color: var(--highlight);
    text-transform: uppercase;
}

.fiche-gift-title {
    font-size: 24px;
    font-style: italic;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 4px;
    margin-bottom: 8px;
}


.fiche-gift-wrapper {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.fiche-gift-sep {
    width: 32px;
    height: 1px;
    background-color: var(--highlight);
    position: relative;
    margin-bottom: 16px;
}

.fiche-gift .fiche-content {
    padding-bottom: 120px;
}

.fiche-gift-wrapper-title {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 8px;
}

.fiche-gift-text {
    font-size: 14px;
    font-weight: 300;
}

.fiche-code {
    background: #ffffff;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 16px;
    font-weight: 700;
    padding: 8px 16px;
    border-radius: 8px;
    margin: 8px 0 16px;
    color: var(--red);
    text-decoration: none;
}



.fiche-description {
    color: white;
    font-size: 15px;
    line-height: 21px;
    font-weight: 400;
    margin-top: 12px;
    max-height: 150px;
    overflow: hidden;
    transition: all .4s ease-in-out;
}

.fiche-description.full {
    max-height: 850px;
}

/*
.fiche-subtitle {
    font-size: 21px;
    line-height: 21px;
    letter-spacing: .5px;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
    margin: 24px 0 0;
}
*/

.fiche-subtitle {
    margin: 24px 0 0;
    font-weight: 700;
    font-style: italic;
    font-size: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.fiche-address-text {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    opacity: .7;
}

.address-line {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.fiche-spot-success-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}

.success-item {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
}


.success-badge {
    display: flex;
    width: 76px;
    height: 76px;
    margin-bottom: 10px;
}



.account-success-title,
.success-title {
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    padding: 0px 10px;
    letter-spacing: .5px;
    opacity: .8;
    text-align: center;
    text-transform: uppercase;
    height: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    font-style: italic;
}

.account-success-title {
    opacity: 1;
}


.success-progress {
    width: 50px;
    height: 3px;
    background-color: white;
    border-radius: 3px;
    margin-top: 12px;
}

.progress-bar {
    background-color: var(--pink);
    width: 50%;
    height: 100%;
}

.see-less,
.see-more {
    font-style: italic;
    font-weight: 700;
    text-decoration: underline;
    color: var(--purple-light);
    font-size: 15px;
}

.small-circle {
    width: 5px;
    height: 5px;
    border-radius: 4px;
    background-color: var(--purple-light);
    margin: 0 8px;
}

.fiche-type-game .small-circle {
    background-color: var(--orange-light);
}

.fiche-type-event .small-circle {
    background-color: var(--blue-light);
}

.fiche-type-game .see-more,
.fiche-type-game .see-less,
.fiche-type-game .fiche-type {
    color: var(--orange-light);
}


.fiche-type-event .see-more,
.fiche-type-event .see-less,
.fiche-type-event .fiche-type {
    color: var(--blue-light);
}


.fiche-type-classic .cta {
    background-color: var(--purple);
}

.fiche-type-game .cta {
    background-color: var(--orange);
}

.fiche-type-event .cta {
    background-color: var(--blue);
}

.fiche-type-classic a {
    color: var(--purple-light);
}

.fiche-type-game a {
    color: var(--orange-light);
}


.fiche-type-event a {
    color: var(--blue-light);
}

.fiche-spot .cta {
    color: white;
}

.fiche-info {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0 4%;
    color: white;
}

.fiche-title {
    font-size: 24px;
    font-style: italic;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 4px;
    margin-bottom: 8px;
}

.fiche-under-title {
    display: flex;
    font-weight: 500;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: .5px;
    align-items: center;
    font-style: italic;
    flex-wrap: wrap;
}

.fiche-type {
    color: var(--purple-light);
}

.fiche-distance {
    color: white;
}


.fiche-game-card-bkg {
    width: 100%;
    aspect-ratio: 305/140;
    object-fit: cover;


}

#games .fiche-game-card-bkg {
    aspect-ratio: 4/5;
    border-radius: 16px;
    background: var(--light);
}

.game-card-cta {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 8px
}

.game-card-cta .cta {
    margin: 6px 0;
}


.unlock .fiche-game-card-bkg {
    mix-blend-mode: normal;
    opacity: 1;
}

.game-line {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--middle);
    border-radius: 16px;
    margin-top: 16px;
    text-decoration: none;
    flex-direction: column;
    overflow: hidden;

}

body:not(#games) .game-line {
    pointer-events: none;
}


.game-line.unlock {
    pointer-events: auto !important;
}

.game-card-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 12px;
    color: white;
}

.game-card-content .cta {
    margin-bottom: 0;
}

.game-card-text {
    font-size: 15px;
}

.game-card-title {
    color: var(--orange-light);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 21px;
    line-height: 21px;
    margin-bottom: 8px;
}

.game-card-img {
    mix-blend-mode: luminosity;
    opacity: .7;
}



.unlock .game-card-img {
    mix-blend-mode: normal;
    opacity: 1;
}



.game-card-main {
    order: 2;
    pointer-events: none;
    text-decoration: none;
}

.game-card-main.unlock {
    order: 1;
    pointer-events: auto;
}

.game-card-content .cta {
    margin-top: 16px;
}

.game-card-text {
    font-size: 15px;
}

.game-points {
    font-weight: 800;
}


.game-line .text-far {
    display: block;
}

.game-line .text-near {
    display: none;
}



.game-line.unlock .text-far {
    display: none;
}

.game-line.unlock .text-near {
    display: block;
}

body:not(#games) .game-line .cta {
    display: none;
}

body .game-line.unlock .cta {
    display: flex !important;
}

.game-fiche-card.lock:not(.preprod) .fiche-game-card-main {
    pointer-events: none;
}

.game-fiche-card.unlock {
    order: 1;
}

.credit {
    margin: 12px 0;
    font-style: italic;
    opacity: .7;
    font-size: 13px;
}

.fiche-info-utile {
    color: white;
    font-size: 15px;
    line-height: 21px;
    font-weight: 400;
    margin-top: 10px;
}

.fiche-info ul {
    padding: 0 4% 0 8%;
}

.fiche-info li {
    margin: 8px 0;
}

.fiche-info span {
    font-weight: 700;
}

.address-map {
    width: 100%;
    height: 180px;
    background-color: var(--light);
    border-radius: 16px;
    margin-top: 16px;
    margin-bottom: 8px;
    overflow: hidden;
    pointer-events: none !important;
}

.address-map-wrapper {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 2px;
}

.address-map-over {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    background: transparent;
    top: 0;
    cursor: pointer;
}

.address-map-wrapper {
    transition: all .4s ease-in-out;
}

.address-map-wrapper:hover {
    opacity: .5;
}

.address-map div {
    pointer-events: none !important;
}

.event-list-page {
    margin-top: 32px;
}

.fiche-poi-under {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    margin-top: 4px;
}





/* event */

.home-events {
    display: none;
}

/* end event */


.home-ranking {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.rank-picture {
    width: 92px;
    height: 92px;
    border-radius: 96px;
    overflow: hidden;
    position: relative;
}

.rank-letters {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .5);
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 4px;
    padding-left: 4px;
    text-transform: uppercase;
    color: white;
}

.rank-round {
    position: absolute;
    width: 100px;
    height: 100px;
    background: white;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.first .rank-round {
    background: linear-gradient(120deg, #d19f33 0%, #f6d17c 100%);
    left: 0;
}

.second .rank-round {
    border-radius: 80px;
    background: linear-gradient(120deg, #828282 0%, #eaeaea 100%);
    right: 0;
}

.third .rank-round {
    background: linear-gradient(120deg, #B16308 0%, #F5BB78 100%);
    left: 0;
}


.rank-card {
    background: var(--light);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 84px;
    border-radius: 16px;
    padding-left: 20px;
    position: relative;
    transition: all .4s ease-in-out;
}

.rank-position {
    position: absolute;
    top: 14px;
    right: 14px;
    font-size: 18px;
    font-weight: 700;
}

.rank-list {
    width: 100%;
}

.rank-item {
    background: var(--light);
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 16px;
    margin: 12px;
}

.rank-item-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rank-item-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.rank-item:nth-child(even) {
    background: var(--middle);
}


.rank-name,
.rank-item-name {
    font-weight: 700;
    font-size: 16px;
    font-style: italic;
}

.rank-item-pseudo,
.rank-pseudo {
    font-size: 16px;
    font-style: italic;
    color: var(--gold);
}

.rank-position,
.rank-item-position {
    color: var(--gold);
}

.rank-score,
.rank-item-score {
    font-weight: 700;
    font-size: 16px;
    font-style: italic;
    text-transform: uppercase;
}

.rank-left-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 16px;
}

.mini-round-flag {
    width: 48px;
    height: 36px;
    border-radius: 8px;
}

.home-countdown {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.second .rank-position {
    right: 116px;
}

.third .rank-card,
.first .rank-card {
    padding-left: 116px;
}

.rank-main {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 16px 0;
    width: 100%;
}

.ladder {
    margin-bottom: 16px;
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
}


.map-preloader {
    position: fixed;
    z-index: 20;
    width: 100%;
    height: 100%;
    background: var(--middle);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.map-preloader.off {
    opacity: 0;
    pointer-events: none;
    transition: all .4s .4s ease-in-out;
}


.map-preloader .data-preloader-logo {
    opacity: .1;
}

.map-preloader .data-preloader-anim {
    opacity: .4;
    margin-top: 40px;
}

.account-score-wrapper {
    text-decoration: none;
}

.myrank {
    background-color: #665848 !important;
}

#line1 {
    animation: left 16s infinite linear;
}

#line2 {
    animation: right 16s infinite linear;
}

.moving-line {
    display: flex;
    width: auto;
}

@keyframes left {
    0% {
        transform: translateX(0px);
    }

    100% {

        transform: translateX(-1008px);

    }
}

@keyframes right {
    0% {
        transform: translateX(0px);
    }

    100% {

        transform: translateX(1008px);

    }
}

.map-filters {
    height: 100%;
    width: 100%;
    background: #141627e3;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    position: absolute;
    top: 0;
    z-index: 20;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 60px 0px;
    opacity: 0;
    pointer-events: none;
    transition: all .2s ease-in-out;
}


.map-filters.open {

    opacity: 1;
    pointer-events: auto;
}


.cta-map-filter-close {
    background-color: var(--light);
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 36px;
    cursor: pointer;
    position: absolute;
    bottom: 12px;
    left: 12px;
    z-index: 12;
}

.open .cta-map-filter-close {
    pointer-events: auto;
}


.cta-map-filter-close img {
    width: 80%;
}

.map-filter-list {
    display: inline-flex;
    padding: 0 4px 0 16px;
}

.map-filter-list .filter {
    margin-right: 12px;
    margin-bottom: 8px;
    padding: 0 12px 0 12px;
    white-space: nowrap;
}

.map-filter-title {
    display: flex;
    font-weight: 700;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: .5px;
    align-items: center;
    font-style: italic;
    flex-wrap: wrap;
    margin: 16px 0 8px 16px;
}

.map-list-scroll {
    width: 100%;
    overflow-x: scroll;
    display: flex;
}

.moveFiche {
    transition: none !important;
}

.logo-pjtm {
    width: 74px;
}

.logo-pjtm-bottom {
    width: 64px;
}

.fiche-bottom-pjtm {
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    max-width: 360px;
    border: 1px solid rgba(255, 255, 255, .3);
    border-radius: 72px;
    padding: 0 12px 0 8px;
    margin-top: 40px;
}


.bottom-push-content {
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    padding: 20px;
    font-weight: 700;
    font-style: italic;
    font-size: 15px;
}

.push-bottom-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.fiche-contact-content {
    color: white;
    display: flex;
    flex-direction: column;
    padding: 8px;
}

.fiche-contact-content input {
    margin: 8px 0;
    font-size: 16px;
}

textarea {
    background-color: var(--light);
    margin: 8px 0 0;
    border-radius: 8px;
    border: none;
    font-size: 17px;
    line-height: 18px;
    color: white;
    outline: none;
    padding: 10px;
    transition: all .4s ease;
    font-family: "Roboto", sans-serif;
}

.msg-intro {
    padding: 8px;
}

summary {

    font-size: 15px;
    line-height: 15px;
    font-style: italic;
    letter-spacing: .5px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 16px 8px;
    border-top: 1px solid var(--light);

}

details p {
    padding: 8px 16px 32px;
    font-size: 15px;
    line-height: 20px;
}

.psw-notice {
    font-size: 13px;
    font-style: italic;
    opacity: 0.8;
}

.rank-item.my-rank {
    margin: 32px 12px 16px;
    padding-right: 20px;
    background: var(--extra-light);
    border-radius: 32px;
}

#complete_account .header-notif,
#login .header-notif,
#new_account .header-notif {
    display: none;
}

#complete_account .header-account {
    opacity: 0;
    pointer-events: none;
}

.header-lang {
    width: 32px;
    height: 32px;
    background: white;
    border-radius: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
}

.header-flag {
    width: 26px;
    height: 26px;
    background: white;
    border-radius: 46px;
}

.icon-timer {
    width: 64px;
}

.icon-crown {
    width: 68px;
    margin-top: 5px;
}


.game-score-record {
    background-color: var(--dark-alpha);
    height: 50px;
    border-radius: 16px;
    width: 100%;
    bottom: 70px;
    z-index: 200;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-style: italic;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 16px;
    margin: 10px 0;
}


.game-intro {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 20;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 160px 32px 120px;
    background: linear-gradient(0deg, var(--dark) 16%, var(--transparent) 60%);
    transition: all .4s ease-in-out;
}

.game-description {
    background-color: var(--dark-alpha);
    border-radius: 16px;
    padding: 32px 24px 8px 20px;
    max-width: 360px;
    transition: all .4s .1s ease-in-out;
}

.game-description.off {
    transform: scale(.9);
}


.off .game-description {
    transform: scale(.9);
}

.game-description p {
    font-size: 15px;
    font-style: italic;

}

.game-point-intro {
    text-transform: uppercase;
    font-weight: 700;
    font-style: italic;
    padding: 20px 0 0px;

}

.game-bkg {
    width: 150%;
    height: 100%;
    object-fit: cover;
    position: fixed;
    z-index: 1;
    filter: blur(10px);
    filter: brightness(1.5) blur(15px);
    top: 0;
    background-color: var(--dark);
    transition: all 1.2s ease-in-out;
}

.game-training .game-bkg {
    filter: brightness(1) blur(10px);
}

.game-logo {
    width: 92%;
    max-width: 370px;
    max-height: 36vh;
    z-index: 9;
    position: absolute;
    top: 20px;
    transition: all .4s ease-in-out;
}

.game-logo.off {
    transform: scale(.9);
}

.game-page {
    position: fixed;
    top: 0;
    z-index: 12;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 80px;
}




/*
.game-content {
    position: absolute;

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: -40px;
}
*/

.game-item {
    z-index: 10;
    background-color: var(--dark-alpha);
    min-height: auto;
    display: none;
}

.game-training .game-item {
    display: none;
}

.game-score-wrapper {
    position: absolute;
    background-color: var(--dark-alpha);
    height: 50px;
    border-radius: 16px;
    width: 90%;
    bottom: 100px;
    z-index: 200;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-style: italic;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 16px;
    transition: all .8s ease-in-out;
}

.game-score-wrapper.off {
    opacity: 0;
    transform: translateY(100%);
}

@media all and (display-mode: standalone) {
    .game-page {
        padding-bottom: 100px;
    }

    .game-score-wrapper {
        bottom: 120px;
    }
}


.game-score-flag {
    width: 32px;
    height: 24px;
    background: white;
    border-radius: 4px;
    margin-right: 8px;
}

.game-score-left {
    display: flex;
    align-items: center;
}

.game-score {
    font-size: 24px;
}

.spot24-text,
.home-undertitle {
    padding: 2% 4%;
    font-size: 15px;
}

.game-fiche-card {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    text-decoration: none;
    flex-direction: column;
    overflow: hidden;
    width: 46%;
    margin: 2%;
}

.fiche-game-card-main {
    position: relative;
    width: 100%;
    background: var(--light);
    border-radius: 16px;
}


.game-card-breakdance {
    order: 7;
}

.game-card-skate {
    order: 8;
}

.game-card-basket {
    order: 9;
}

.game-card-quiz {
    order: 10;
}

.game-card-memory_surf {
    order: 11;
}

.game-card-memory_bmx {
    order: 12;
}


.game-card-breakdance.unlock {
    order: 1;
}

.game-card-skate.unlock {
    order: 2;
}

.game-card-basket.unlock {
    order: 3;
}

.game-card-quiz.unlock {
    order: 4;
}

.game-card-memory_surf.unlock {
    order: 5;
}

.game-card-memory_bmx.unlock {
    order: 6;
}

.game-record-line {
    display: flex;
    flex-wrap: nowrap;
    font-size: 18px;
    font-style: italic;
    font-weight: 700;
    text-transform: uppercase;
    white-space: nowrap;
    position: absolute;
    bottom: 100px;
    width: 100%;
    overflow: hidden;
    transition: all .8s ease-in-out;
}

@media all and (display-mode: standalone) {
    .game-record-line {
        bottom: 120px;
    }
}


.game-record-block {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.game-record-title {
    color: var(--orange);
    margin-right: 8px;
}

.game-record-flag {
    width: 24px;
    height: 18px;
    background: white;
    border-radius: 3px;
    margin: 0 12px 0 0;
}

.game-line-move {
    display: flex;
    animation: move 15s infinite linear;
    align-items: center;
}

@keyframes move {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}

.small-circle-record {
    width: 8px;
    height: 8px;
    background: white;
    border-radius: 8px;
    margin: 0 20px;
}

.game-img {
    position: absolute;
    transform: scale(.9);
    z-index: 3;
    object-fit: contain !important;
}

.spot-img {
    position: relative;
    z-index: 2;
    mix-blend-mode: luminosity;
}

.game-record {
    margin: 0 16px 0 0;
}


.country-item {
    order: 3;
}

.main-country {
    order: 2;
}

.first-country {
    order: 1;
}


.game-countdown {
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gameover-text,
.number {
    opacity: 0;
    transform: scale(.8);
    position: absolute;
    pointer-events: none;
}

.number.anim {
    animation: pop 1s;
}

.gameover-text.anim {
    animation: pop 2s;
}


@keyframes pop {
    0% {
        opacity: 0;
        transform: scale(.8);
    }

    50% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(1.2);
    }
}


.game-score-add {
    position: absolute;
    right: 16px;
    opacity: 0;
    transform: scale(.8);
}

.game-score-add.anim {
    animation: pop 1s;
}

.game-replay {
    background-color: var(--dark-alpha);
    border-radius: 16px;
    padding: 8px 20px 8px 20px;
    max-width: 360px;
    transition: all .4s ease-in-out;
    position: absolute;
    z-index: 3;
    transition: all .4s ease-in-out;
}

.game-replay.off {
    transform: scale(.9);
}

#page_404 {
    background: var(--highlight);
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.number_404 {
    width: 60vw;
    animation: shake 1s infinite alternate ease-in-out;
    cursor: pointer;
}

@keyframes shake {
    0% {
        transform: rotate(-10deg);
    }

    100% {
        transform: rotate(10deg);
    }
}

.home-logo-video {
    position: absolute;
    z-index: 4;
    width: 232px;
    /* opacity: 0.3; */
    margin-bottom: 20px;
}

#input-email-account {
    pointer-events: none;
    color: #9a9db4;
}

.address-map-icon {
    position: absolute;
    z-index: 20;
    right: 8px;
    bottom: 12px;
    background: var(--dark);
    width: 36px;
    height: 36px;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    pointer-events: none;
}

.address-map-icon img {
    height: 80%;
    width: 80%;
}

.debug-text {
    position: absolute;
    bottom: 4px;
    border-radius: 8px;
    left: 50%;
    transform: translateX(-50%) scale(.8);
    width: 50%;
    height: 54px;
    background: #1c1c20ba;
    color: white;
    font-size: 18px;
    line-height: 16px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px solid transparent;
    display: none;
}

.debug-text.updated {
    border: 3px solid var(--highlight)
}

.debug-text.show {
    display: flex;
}

#cta-info-map,
#cta-debug {
    display: none;
}

.preprod #cta-info-map,
.preprod #cta-debug {
    display: flex;
}

.cta-center {
    transition: all .2s ease-in-out;
}

.cta-center.debug {
    background: #008df1;
}

.cta-center.debug.active {
    border: 2px solid #008df1;
    background: var(--light);
}

.cta-center.updated{
    transform: scale(1.1);
    filter: brightness(1.2);
}

.partner .spot-notif-type,
.spot-card-main.partner .spot-card-type{
    display: none;
}

.partner .spot-card-circle.checked,
.spot-card-main.partner .spot-card-circle.checked {
    background-color: white;
}

.partner .spot-card-circle,
.spot-card-main.partner .spot-card-circle {
    border-color: white;
}

.partner .spot-card-circle.checked path,
.spot-card-main.partner .spot-card-circle.checked path {
    fill:var(--dark);
}

.limited-wrapper {
    position: absolute;
    display: flex;
    top: -12px;
    transform: translateY(-100%);
    background: var(--dark-alpha);
    width: 92%;
    border-radius: 16px;
    padding: 14px 20px 16px 24px;
    justify-content: center;
    align-items: center;
}

.limited-content {
    padding-left: 16px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
}

.limited-wrapper img{
    height: 28px;
}